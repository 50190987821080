import Resource from "./resource.js";
export class Groups extends Resource {
    find = this.makeRequest({
        method: "GET",
        queryParamKeys: [
            "search",
            "q",
            "exact",
            "briefRepresentation",
            "populateHierarchy",
            "first",
            "max",
        ],
    });
    create = this.makeRequest({
        method: "POST",
        returnResourceIdInLocationHeader: { field: "id" },
    });
    updateRoot = this.makeRequest({
        method: "POST",
    });
    /**
     * Single user
     */
    findOne = this.makeRequest({
        method: "GET",
        path: "/{id}",
        urlParamKeys: ["id"],
        catchNotFound: true,
    });
    update = this.makeUpdateRequest({
        method: "PUT",
        path: "/{id}",
        urlParamKeys: ["id"],
    });
    del = this.makeRequest({
        method: "DELETE",
        path: "/{id}",
        urlParamKeys: ["id"],
    });
    count = this.makeRequest({
        method: "GET",
        path: "/count",
    });
    /**
     * Creates a child group on the specified parent group. If the group already exists, then an error is returned.
     */
    createChildGroup = this.makeUpdateRequest({
        method: "POST",
        path: "/{id}/children",
        urlParamKeys: ["id"],
        returnResourceIdInLocationHeader: { field: "id" },
    });
    /**
     * Updates a child group on the specified parent group. If the group doesn’t exist, then an error is returned.
     * Can be used to move a group from one parent to another.
     */
    updateChildGroup = this.makeUpdateRequest({
        method: "POST",
        path: "/{id}/children",
        urlParamKeys: ["id"],
    });
    /**
     * Finds all subgroups on the specified parent group matching the provided parameters.
     */
    listSubGroups = this.makeRequest({
        method: "GET",
        path: "/{parentId}/children",
        urlParamKeys: ["parentId"],
        queryParamKeys: ["search", "first", "max", "briefRepresentation"],
        catchNotFound: true,
    });
    /**
     * Members
     */
    listMembers = this.makeRequest({
        method: "GET",
        path: "/{id}/members",
        urlParamKeys: ["id"],
        catchNotFound: true,
    });
    /**
     * Role mappings
     * https://www.keycloak.org/docs-api/11.0/rest-api/#_role_mapper_resource
     */
    listRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings",
        urlParamKeys: ["id"],
    });
    addRealmRoleMappings = this.makeRequest({
        method: "POST",
        path: "/{id}/role-mappings/realm",
        urlParamKeys: ["id"],
        payloadKey: "roles",
    });
    listRealmRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings/realm",
        urlParamKeys: ["id"],
    });
    delRealmRoleMappings = this.makeRequest({
        method: "DELETE",
        path: "/{id}/role-mappings/realm",
        urlParamKeys: ["id"],
        payloadKey: "roles",
    });
    listAvailableRealmRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings/realm/available",
        urlParamKeys: ["id"],
    });
    // Get effective realm-level role mappings This will recurse all composite roles to get the result.
    listCompositeRealmRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings/realm/composite",
        urlParamKeys: ["id"],
    });
    /**
     * Client role mappings
     * https://www.keycloak.org/docs-api/11.0/rest-api/#_client_role_mappings_resource
     */
    listClientRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings/clients/{clientUniqueId}",
        urlParamKeys: ["id", "clientUniqueId"],
    });
    addClientRoleMappings = this.makeRequest({
        method: "POST",
        path: "/{id}/role-mappings/clients/{clientUniqueId}",
        urlParamKeys: ["id", "clientUniqueId"],
        payloadKey: "roles",
    });
    delClientRoleMappings = this.makeRequest({
        method: "DELETE",
        path: "/{id}/role-mappings/clients/{clientUniqueId}",
        urlParamKeys: ["id", "clientUniqueId"],
        payloadKey: "roles",
    });
    listAvailableClientRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings/clients/{clientUniqueId}/available",
        urlParamKeys: ["id", "clientUniqueId"],
    });
    listCompositeClientRoleMappings = this.makeRequest({
        method: "GET",
        path: "/{id}/role-mappings/clients/{clientUniqueId}/composite",
        urlParamKeys: ["id", "clientUniqueId"],
    });
    /**
     * Authorization permissions
     */
    updatePermission = this.makeUpdateRequest({
        method: "PUT",
        path: "/{id}/management/permissions",
        urlParamKeys: ["id"],
    });
    listPermissions = this.makeRequest({
        method: "GET",
        path: "/{id}/management/permissions",
        urlParamKeys: ["id"],
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}/groups",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
